import styles from "./FormSection.module.scss";

export default function FormSection({ heading, subHeading }) {
  return (
    <div className={styles.formSectionContainer}>
      <div className={styles.headingContainer}>
        <h1>{heading}</h1>
        <h3>{subHeading}</h3>
      </div>
      <div className={styles.formContainer}>
        <form>
          <label htmlFor="name">Name</label>
          <input id="name" />

          <label htmlFor="query">Query</label>
          <textarea id="query" />

          <label htmlFor="type">Type</label>
          <select id="type">
            <option value="hello">Helo</option>
          </select>

          <label htmlFor="myRange">Money</label>
          <input type="range" min="1" max="100" step="10" id="myRange"></input>
        </form>
      </div>
    </div>
  );
}
