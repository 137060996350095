

export const sliderValuesProductDesign = [
    "maximise user experience",
    'suit your business needs',
    'deliver a unique experience',
    'are easy to use',
    'simply look stunning',
];

export const sliderValuesWebDevelopment = [
    "are scalable and maintainable",
    "use the latest technologies",
    'are innovative and unique',
    'solve your business problems',
    'apply best practices',
];

export const sliderValuesMobileDevelopment = [
    'transform user experiences',
    "connect users on the go",
    'reinvent your brand',
    'build your reach',
    "are cross platform",
];



