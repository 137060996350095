import styles from "./TwoWayCarousel.module.scss";

const TwoWayCarousel = ({ content1, content2, text }) => {
  return (
    <div className={styles.container}>
      <p className={styles.subtitle}>{text}</p>
      <div className={styles.wrapper1}>{content1}</div>
      <div className={styles.wrapper2}>{content2}</div>
    </div>
  );
};

export default TwoWayCarousel;
