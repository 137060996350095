import React from "react";
import styles from "./styles/MobileDevelopment.module.scss";

const MobileDevelopment = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>MobileDevelopment</div>
    </div>
  );
};

export default MobileDevelopment;
