import styles from "./CardGridArea.module.scss";

export default function CardGridArea({ heading, description, children }) {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>{heading}</h2>
      {description && <p className={styles.body1}>{description}</p>}
      <div className={styles.grid}>{children}</div>
    </div>
  );
}
