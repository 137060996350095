import styles from "./Tile.module.scss";
import classNames from "classnames";

const Tile = ({ heading, Icon, image, id, onClick, selectedId }) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: selectedId === id,
      })}
      onClick={() => onClick(id)}
    >
      <div className={styles.header}>
        {Icon && <Icon className={styles.icon} />}
        <p className={styles.tileTitle}>{heading}</p>
      </div>
    </div>
  );
};

export default Tile;
