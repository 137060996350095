import styles from "./PeopleCard.module.scss";
import Link from "../../assets/About/link.svg";
import LinkedIn from "../../assets/About/linkedIn.svg";

export default function PeopleCard ({ icon, linkedIn, portfolio, name }) {
  const url = `https://${portfolio}`;
  const onClick = () => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className={styles.peopleCard} onClick={onClick}>
      <img src={icon} alt={name} onClick={onClick} className={styles.profile} />
      <p>{name}</p>
      <a href={url} target="_blank" rel="noreferrer" className={styles.url}>
        <img src={Link} alt="Portfolio link" className={styles.linkIcon} /> {portfolio}
      </a>
      <a
        className={styles.linkedInIcon}
        href={linkedIn}
        target="_blank"
        rel="noreferrer"
      >
        <img src={LinkedIn} alt="LinkedIn" />
      </a>
    </div>
  );
}
