import Button from "../Button/Button";
import styles from "./TilesContainer.module.scss";
import { HiArrowLongRight } from "react-icons/hi2";

export default function TilesContainer({ children, selectedTile }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <h5 className={styles.contentHeading}>{selectedTile.heading}</h5>
            <p className={styles.contentParagraph}>{selectedTile.text}</p>
            <div className={styles.contentActions}>
              <Button
                customcss={styles.readMore}
                label="Read More"
                to={selectedTile.link}
                size="large"
                type="tertiary"
                icon={<HiArrowLongRight />}
                iconPosition="right"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
