import styles from "../Button/Button.module.scss";
import classnames from "classnames";
import { mapSize, mapIconPosition, mapStyle } from "./constants";
import { Link } from "react-router-dom";

const Button = ({
  label,
  icon,
  size,
  iconPosition,
  type,
  onClick,
  to,
  customcss,
}) => {
  return to ? (
    <Link to={to}>
      <button
        className={classnames(styles.button, {
          [mapSize[size]]: size,
          [mapIconPosition[iconPosition]]: iconPosition,
          [mapStyle[type]]: type,
          [styles.iconButton]: icon,
          [customcss]: customcss,
        })}
      >
        {icon && icon}
        {label}
      </button>
    </Link>
  ) : (
    <button
      onClick={onClick}
      className={classnames(styles.button, {
        [mapSize[size]]: size,
        [mapIconPosition[iconPosition]]: iconPosition,
        [mapStyle[type]]: type,
        [styles.iconButton]: icon,
        [customcss]: customcss,
      })}
    >
      {icon && icon}
      {label}
    </button>
  );
};

export default Button;
