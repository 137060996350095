import React from "react";
import styles from "../Home.module.scss";
import { clientArray1, clientArray2 } from "../../../constants/Home";
import Pill from "../../../components/Pill/Pill";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import TwoWayCarousel from "../../../components/TwoWayCarousel/TwoWayCarousel";

const Section2 = () => {
  return (
    <div className={styles.section2}>
      <div className={styles.wrapper}>
        <SectionHeading
          heading="Transforming industries, one client at a time"
          subHeading="Who we have worked with"
        />
        <TwoWayCarousel
          text="Our team has varied experience across different sectors, each with their own distinct demands and difficulties to overcome."
          content1={clientArray1.map((item, index) => (
            <Pill image={item.image} key={index} text={item.text} />
          ))}
          content2={clientArray2.map((item, index) => (
            <Pill image={item.image} key={index} text={item.text} />
          ))}
        />
      </div>
    </div>
  );
};

export default Section2;
