import styles from "../styles/pages/Founders.module.scss";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import PeopleCard from "../components/PeopleCard/PeopleCard";
import Aman from "../assets/About/aman.png";
import Tabs from "../assets/About/tabs.png";

const Founders = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.contentContainer}>
          <SectionHeading heading={"Meet Our Founders"} />
          <p className={styles.text}>
            Our founders are a dynamic duo of software developers who share a
            love and passion for technology and coding. With a commitment to
            delivering user-centric products, they bring a wealth of knowledge
            and expertise to the company, and are dedicated to helping
            businesses achieve their goals through innovative software
            solutions.
          </p>
        </div>
        <div className={styles.peopleContainer}>
          <PeopleCard
            icon={Aman}
            name="Aman Khan"
            portfolio="www.amn.today"
            linkedIn="https://www.linkedin.com/in/aman-khan-4ba984185/"
          />
          <PeopleCard
            icon={Tabs}
            name="Tabassum Mulla"
            portfolio="www.tabassum.co.uk"
            linkedIn="https://www.linkedin.com/in/tabassummulla/"
          />
        </div>
      </div>
    </div>
  );
};

export default Founders;
