import { ReactComponent as PD } from '../../assets/Home/Icons/ProductDesign.svg';
import { ReactComponent as WD } from '../../assets/Home/Icons/WebDev.svg';
import { ReactComponent as MD } from '../../assets/Home/Icons/MobDev.svg';
import { ReactComponent as AN } from '../../assets/Home/Icons/MVP.svg';
import { ReactComponent as MT } from '../../assets/Home/Icons/Maintain.svg';
import { ReactComponent as QA } from '../../assets/Home/Icons/QA.svg';
import { ReactComponent as EP } from '../../assets/Home/Icons/Expertise.svg';
import { ReactComponent as CC } from '../../assets/Home/Icons/Comms.svg';
import { ReactComponent as CT } from '../../assets/Home/Icons/Custom.svg';
import { ReactComponent as FX } from '../../assets/Home/Icons/Flexibility.svg';
import { ReactComponent as CS } from '../../assets/Home/Icons/CustomerSupport.svg';
import { ReactComponent as QR } from '../../assets/Home/Icons/QandR.svg';


import sky from '../../assets/clients/sky.png';
import boohoo from '../../assets/clients/boohoo.png';
import andDigital from '../../assets/clients/and.png';
import theVeryGroup from '../../assets/clients/theverygroup.png';
import missguided from '../../assets/clients/missguided.png';
import livePerson from '../../assets/clients/liveperson.png';
import uW from '../../assets/clients/uw.png';
import businessGrowthHub from '../../assets/clients/bgrowthub.png';
import coop from '../../assets/clients/coop.png';
import openMoney from '../../assets/clients/openmoney.png';
import hollandBarrett from '../../assets/clients/hb.png';
import keyGroup from '../../assets/clients/keygroup.png';
import onTheBeach from '../../assets/clients/onthebeach.png';


export const sliderValues = [
  'meets your needs',
  'builds your digital presence',
  'is simplified for all',
];

export const whatWeOffer = [
  {
    icon: PD,
    link: "services/product-design",
    heading: 'Product Design',
    subHeading: 'A tailor-made approach to bring visual value to your project',
    text: 'A tailor-made approach to bring visual value to your project. Our UX designers work with you to create incredible user experiences and effective designs. Our team of experts have extensive experience in designing and developing products that are both visually appealing and functional, using the latest technologies and tools.',
  },
  {
    icon: WD,
    link: "services/web-development",
    heading: 'Web Development',
    subHeading:
      'We offer a wide range of web development services for your business',
    text: 'We offer a wide range of web development services, including custom websites, e-commerce platforms, and web applications. As a Software development company, we are well versed with the latest technologies and best practices to deliver capabilities to leverage your success. Our engineers work with you to create software solutions to cover your business needs and consumer demands. ',
  },
  {
    icon: MD,
    link: "services/mobile-development",
    heading: 'Mobile Development',
    subHeading:
      'GrapeTech also offers mobile development services for your business',
    text: "GrapeTech also offers mobile development services for businesses looking to expand their reach and new customers. Whether you're looking to create a new mobile app or optimize an existing one, our team of experts can help you develop high-quality, user-friendly mobile solutions that are designed to meet the needs of your target audience.",
  },
  {
    icon: AN,
    link: "services/mvp",
    heading: 'Rapid MVP',
    subHeading:
      'We can use your data to uncover insight and deliver value for growth',
    text: 'At GrapeTech we value the significance of speed and agility in launching new products and services. Which is why we offer a rapid MVP (Minimum Viable Product) development process where we work with you to understand your business needs, and target audience in order to create a fully functional MVP in a fraction of the time it would take for traditional development methods. By quickly launching products into the market, we are able to gather valuable feedback, refine offerings, and minimize risk while maximizing ROI.',
  },
  {
    icon: MT,
    link: "services/maintenance",
    heading: 'Maintenance',
    subHeading:
      'Our maintenance plan is designed to help businesses keep their technology up to date and running smoothly',
    text: 'At GrapeTech we offer you support from the get go. Our maintenance plan is designed to help businesses keep their technology up to date and running smoothly. Our team of experts can provide ongoing support and maintenance services, including software updates, security patches, and performance optimization.',
  },
  {
    icon: QA,
    link: "services/qa-assurance",
    heading: 'Quality Assurance',
    subHeading:
      'We understand the importance of delivering high-quality software',
    text: 'We understand the importance of delivering high-quality software. That is why we offer quality assurance services, including testing, debugging, and performance optimization. We incorporate this in each stage to reduce bugs and flaws from the start.',
  },
];

export const whyChooseUs = [
  {
    icon: EP,
    heading: 'Expertise and Experience',
    text: 'Our team of software developers is highly experienced and knowledgeable, with a track record of delivering top-quality software solutions across a range of industries. We have the expertise to help you achieve your goals, no matter how complex or ambitious they may be.',
  },
  {
    icon: CT,
    heading: 'Custom Solutions',
    text: " We understand that one size does not fit all when it comes to software development. That's why we offer custom solutions tailored to meet the specific needs of your business. Our team will work closely with you to understand your unique requirements and develop a solution that delivers the results you need.",
  },
  {
    icon: FX,
    heading: 'Flexibility and Agility',
    text: "We understand that the business landscape is constantly evolving, and that your software needs may change over time. That's why we offer flexible and agile solutions that can adapt to your changing needs, helping you stay ahead of the curve.",
  },
  {
    icon: CC,
    heading: 'Communication and Collaboration',
    text: "We believe in open and transparent communication with our clients. Our team will work closely with you to understand your needs and requirements, and we'll keep you informed every step of the way. This collaborative approach helps us identify and resolve any potential roadblocks before they impact the timeline of your project.",
  },
  {
    icon: CS,
    heading: 'Exceptional Customer Support',
    text: 'At GrapeTech, we believe in going above and beyond to support our clients. Our team is always available to answer any questions or concerns, and we strive to provide the highest level of customer service possible.',
  },
  {
    icon: QR,
    heading: 'Quality and Reliability',
    text: 'At GrapeTech, we believe in delivering software solutions that are not only functional but also reliable and of the highest quality. We use the latest technologies and best practices to ensure that your software is robust, secure, and scalable.',
  },
];


export const clientArray1 = [
  {
    text: "Sky", image: sky
  },
  {
    text: "OpenMoney",
    image: openMoney

  },
  {
    text: "Boohoo", image: boohoo
  },
  {
    text:
      "On the beach", image: onTheBeach
  },
  {
    text: "LivePerson", image: livePerson
  },
  {
    text: "Missguided", image: missguided
  },
  {
    text: "The Very Group", image: theVeryGroup
  },
];
export const clientArray2 = [
  {
    text: "Key Group", image: keyGroup
  },
  {
    text: "Utility Warehouse", image: uW
  },
  {
    text: "Holland & Barrett", image: hollandBarrett
  },
  {
    text: "And Digital", image: andDigital
  },
  {
    text: "Coop", image: coop
  },
  {
    text: "Business Growth Hub", image: businessGrowthHub
  },
];
