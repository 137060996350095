import { Link } from "react-router-dom"
import styles from "./IconButton.module.scss"

const ButtonContent = ({ icon, onClick }) => {
    return (
        <span className={styles.iconButton} onClick={onClick ?? undefined} >
            {icon}
        </span >
    )
}

const IconButton = ({ icon, to, onClick }) => {
    return (
        to
            ? <Link to={to}>
                <ButtonContent icon={icon} />
            </Link> :
            <ButtonContent onClick={onClick} icon={icon} />
    )
}

export default IconButton;