import React from "react";
import styles from "./styles/Maintenance.module.scss";

const Maintenance = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>Maintenance</div>
    </div>
  );
};

export default Maintenance;
