import Carousel from "../../components/Carousel/Carousel";
import styles from "./Home.module.scss";
import { sliderValues } from "../../constants/Home";

import { ReactComponent as HeaderImage } from "../../assets/illustrations/header.svg";
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import Section1 from "./sections/Section1";
import Section2 from "./sections/Section2";

const Home = () => {
  return (
    <div className={styles.container}>
      <Carousel
        preHeading="Delivering software that"
        textArray={sliderValues}
        HeaderImage={HeaderImage}
      />

      <div className={styles.content}>
        <FadeInSection section={<Section1 />} />
        <FadeInSection section={<Section2 />} />
      </div>
    </div>
  );
};

export default Home;
