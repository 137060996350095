import { ReactComponent as AboutLogo } from "../../assets/book-1.svg";
import { ReactComponent as ContactLogo } from "../../assets/sms.svg";
import { ReactComponent as GetStarted } from "../../assets/rocket-launch.svg";

export const mobileIconStyle = { width: "20px", fill: "#f6e7d7" };
export const links = [
  {
    key: "about",
    to: "/about",
    label: "About us",
    icon: (
      <AboutLogo width={mobileIconStyle.width} fill={mobileIconStyle.fill} />
    ),
  },
  {
    key: "contact",
    to: "/contact",
    label: "Contact us",
    icon: (
      <ContactLogo width={mobileIconStyle.width} fill={mobileIconStyle.fill} />
    ),
  },
];

export const mobileLinks = [
  ...links,
  {
    key: "getstarted",
    to: "/getstarted",
    label: "Get Started",
    icon: (
      <GetStarted width={mobileIconStyle.width} fill={mobileIconStyle.fill} />
    ),
  },
];
