import styles from "./Footer.module.scss";
import Logo from "../../assets/grape.png";
import Instagram from "./Icons/instagram.svg";
import LinkedIn from "./Icons/linkedIn.svg";

export default function Footer() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLine} />
      <div className={styles.footerContentContainer}>
        <img src={Logo} alt="GrapeTech Logo" />

        <div className={styles.footerSocialsContainer}>
          <img src={Instagram} alt="Instagram" />
          <img src={LinkedIn} alt="LinkedIn" />

          <p>Terms of Service</p>
        </div>
      </div>
    </div>
  );
}
