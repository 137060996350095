export const sliderValues = [
  'Honesty and transparency drives our business. We believe in doing the right thing.',
  'Innovation is at the core of our work. Pushing boundaries to achieve client goals.',
  'Working together to achieve great results. Customized solutions for unique client needs.',
  'Committed to delivering results beyond expectations. Constantly improving to stay ahead.',
  'Available to support clients and respond to their tech needs. Helping businesses succeed.',
];


/**Innovate to elevate: We innovate to elevate your business through technology.
Together we win: We work together to win together with our clients.
Customer first, always: We put our customers first, always, to deliver exceptional software solutions.***/


/**Innovate. Transform. Thrive. - We value innovation and strive to transform businesses through cutting-edge software solutions that help them thrive in the digital age.

Together. Stronger. Better. - We believe in the power of teamwork and collaboration, and we work together with our clients to create software solutions that are stronger and better than what we could create alone.

Customer success, our passion. - Our passion is helping our clients succeed. We are customer-focused, and we work tirelessly to understand their needs, develop solutions that meet those needs, and provide ongoing support to ensure their success. */