import { useState, useEffect, useRef } from "react";
import Dots from "./components/Dots/Dots";
import Slide from "./components/Slide/Slide";
import styles from "./Carousel.module.scss";
import Button from "../Button/Button";
import { HiArrowLongRight } from "react-icons/hi2";
import classnames from "classnames";

export default function Carousel({
  preHeading,
  textArray,
  delay = 3500,
  isItalics,
  HeaderImage,
}) {
  const [sliderIndex, setSliderIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  //reset timeout and change slide using index
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setSliderIndex((prevIndex) =>
          prevIndex === textArray.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [delay, sliderIndex, textArray.length]);

  return (
    <div className={styles.slideshow}>
      {HeaderImage && <HeaderImage className={styles.headerImage} />}
      <h1>{preHeading}</h1>
      <div
        className={styles.slideshowSlider}
        style={{ transform: `translate3d(${-sliderIndex * 100}%, 0, 0)` }}
      >
        {textArray.map((heading, index) => (
          <Slide
            heading={heading}
            index={index}
            key={index}
            isItalics={isItalics}
          />
        ))}
      </div>

      <div
        className={classnames(styles.action, {
          [styles.carouselWithImage]: HeaderImage,
        })}
      >
        <Button
          size="medium"
          type={"quaternary"}
          label="Estimate Project"
          to="getstarted"
          icon={<HiArrowLongRight />}
          iconPosition="right"
        />
      </div>
      <div className={styles.slideshowDots}>
        {textArray.map((_, index) => (
          <Dots
            setSliderIndex={setSliderIndex}
            sliderIndex={sliderIndex}
            key={index}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}
