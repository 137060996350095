import FormSection from "../components/FormSection/FormSection";
import styles from "../styles/pages/Contact.module.scss";
const Contact = () => {
  //form object

  return (
    <div className={styles.container}>
      <FormSection
        heading="Contact Us"
        subHeading="Ready to take your digital presence to the next level?"
      />
    </div>
  );
};

export default Contact;
