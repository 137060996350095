import Carousel from "../components/Carousel/Carousel";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import styles from "../styles/pages/About.module.scss";
import { sliderValues } from "../constants/About";
import CardGridArea from "../components/CardGridArea/CardGridArea";
import DetailCard from "../components/Section/DetailCard";
import { whyChooseUs } from "../constants/Home";
import Button from "../components/Button/Button";
import { useState } from "react";
import Modal from "../components/Modal/Modal";
import Founders from "./Founders";

const About = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleClickFounders = () => setShowModal(true);

  return (
    <div className={styles.container}>
      {showModal ? <Modal onClose={handleCloseModal}><Founders /> </Modal> : null}
      <Carousel
        textArray={sliderValues}
        preHeading="Our Values"
        delay={5000}
        isItalics
      />
      <div className={styles.contentContainer}>
        <div className={styles.section1}>
          <SectionHeading heading={"Who Are We?"} />
          <p>
            We are a team of experienced software developers and technology
            experts, dedicated to helping businesses achieve their goals through
            innovative software solutions. Our passion for technology and our
            commitment to excellence are the driving forces behind everything we
            do.
          </p>
          <p>
            At GrapeTech, we believe that technology should not only be
            functional but also intuitive and user-friendly. That's why we work
            closely with our clients to understand their unique needs and
            develop custom software solutions that solve their problems and
            enhance their operations. Our team of experienced developers,
            designers, and project managers are passionate about creating
            software that delivers real value to our clients. We pride ourselves
            on our attention to detail, our commitment to quality, and our
            ability to deliver projects on time and within budget.
          </p>
          <br></br>
          <Button
            type="tertiary"
            onClick={handleClickFounders}
            size="large" iconPosition="left"
            label="Meet our Founders"
          />
        </div>
        <div className={styles.section2}>
          <CardGridArea heading={"Why choose us?"}>
            {whyChooseUs.map((item, index) => (
              <DetailCard heading={item.heading} key={index} text={item.text} />
            ))}
          </CardGridArea>
        </div>
      </div>
    </div>
  );
};

export default About;
