import { useRef } from "react";
import styles from "../FadeInSection/FadeInSection.module.scss";
import classNames from "classnames";
import { useInViewport } from "react-in-viewport";

const FadeInSection = ({ section }) => {
  const myRef = useRef();
  const { inViewport } = useInViewport(myRef);
  return (
    <div
      className={classNames(styles.fadeInSection, {
        [styles.isVisible]: inViewport,
      })}
      ref={myRef}
    >
      {section}
    </div>
  );
};

export default FadeInSection;
