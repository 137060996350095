import styles from "./Card.module.scss";

const Card = ({ img, title, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.section1}>{img}</div>
      <div className={styles.section2}>
        <h6>{title}</h6>
        <p className={styles.sectionText}>{text}</p>
      </div>
    </div>
  );
};

export default Card;
