import styles from "./Pill.module.scss";

const Pill = ({ text, image }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {image && <img className={styles.image} alt={text} src={image} />}
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};

export default Pill;
