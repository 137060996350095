import React from "react";
import styles from "./styles/MVP.module.scss";

const MVP = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>MVP</div>
    </div>
  );
};

export default MVP;
