import styles from "./Services.module.scss";
import QualityAssurance from "./sections/QualityAssurance";
import FadeInSection from "../../components/FadeInSection/FadeInSection";
import { useLocation } from "react-router-dom";
import ProductDesign from "./sections/ProductDesign";
import WebDevelopment from "./sections/WebDevelopment";
import MobileDevelopment from "./sections/MobileDevelopment";
import Maintenance from "./sections/Maintenance";
import MVP from "./sections/MVP";
import { sliderValues } from "../../constants/Home";
import {
  sliderValuesProductDesign,
  sliderValuesWebDevelopment,
  sliderValuesMobileDevelopment,
} from "../../constants/Services";

import { ReactComponent as Design } from "../../assets/illustrations/design.svg";
import { ReactComponent as Development } from "../../assets/illustrations/development.svg";
import { ReactComponent as MobileDev } from "../../assets/illustrations/mobiledev.svg";
import { ReactComponent as Mvp } from "../../assets/illustrations/mvp.svg";

const Services = () => {
  const location = useLocation();

  const config = {
    "/services/product-design": {
      section: <ProductDesign />,
      headerImage: Design,
      carouselTitle: "Designing Products that",
      carouselValues: sliderValuesProductDesign,
    },
    "/services/web-development": {
      section: <WebDevelopment />,
      headerImage: Development,
      carouselTitle: "Developing Products that",
      carouselValues: sliderValuesWebDevelopment,
    },
    "/services/mobile-development": {
      section: <MobileDevelopment />,
      headerImage: MobileDev,
      carouselTitle: "Building Mobile-Apps that",
      carouselValues: sliderValuesMobileDevelopment,
    },
    "/services/mvp": {
      section: <MVP />,
      headerImage: Mvp,
      carouselTitle: "Building Products that",
      carouselValues: sliderValues,
    },
    "/services/maintenance": {
      section: <Maintenance />,
      headerImage: Design,
      carouselTitle: "Developing Products that",
      carouselValues: sliderValues,
    },
    "/services/qa-assurance": {
      section: <QualityAssurance />,
      headerImage: Design,
      carouselTitle: "Developing Products that",
      carouselValues: sliderValues,
    },
  };

  const selectedConfig = config[location.pathname];

  return (
    <div className={styles.container}>
      <FadeInSection section={selectedConfig.section} />
    </div>
  );
};

export default Services;
