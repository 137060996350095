import styles from "./NavBar.module.scss";
import Logo from "../../assets/headerlogo.png";
import GrapeLogo from "../../assets/grape.png";

import { Link, NavLink } from "react-router-dom";
import { links } from "./constants";
import Button from "../Button/Button";
const NavBar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <div className={styles.combinedLogo}>
          <Link to={"/"}>
            <img className={styles.logo} src={Logo} alt="nav-logo" />
            <img className={styles.grapeLogo} src={GrapeLogo} alt="nav-logo" />
          </Link>
        </div>
      </div>

      <div className={styles.content}>
        <nav className={styles.navcontainer} id="nav">
          {links.map((link) => (
            <NavLink
              to={link.to}
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
              key={link.key}
            >
              {link.label}
            </NavLink>
          ))}
        </nav>
        <div className={styles.actions}>
          <Button
            customcss={styles.getstarted}
            label="Get Started"
            to="/getstarted"
            size="large"
            type="tertiary"
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
