import classNames from "classnames";
import styles from "./Slide.module.scss";

export default function Slide({ heading, isItalics }) {
  return (
    <div
      className={classNames(styles.slide, {
        [styles.italics]: isItalics,
      })}
    >
      <h1>{heading}</h1>
    </div>
  );
}
