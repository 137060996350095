import React from 'react';
import styles from './styles/App.module.scss';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import GetStarted from './pages/GetStarted';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Founders from './pages/Founders';
import Services from "./pages/Services/Services";

const App = () => {
  return (
    <div className={styles.app}>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/founders" element={<Founders />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/getstarted" element={<GetStarted />} />
        <Route
          path="services/:service"
          element={<Services />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
