import styles from "./Modal.module.scss"
import { AiOutlineClose } from "react-icons/ai";
import IconButton from "../IconButton/IconButton";

const Modal = ({ onClose, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.header}>
                    <IconButton icon={<AiOutlineClose />} onClick={onClose} />
                </div>
                <div className={styles.overflowScrollGradient}>
                    <div className={styles.content}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal