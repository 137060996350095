import React from "react";
import styles from "./styles/ProductDesign.module.scss";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import { ReactComponent as Design } from "../../../assets/illustrations/design.svg";
import Card from "../../../components/Card/Card";

const ProductDesign = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.section1}>
          <div className={styles.sectionContent}>
            <SectionHeading
              subHeading="Product Design"
              heading="A tailor-made approach to bring visual value to your project"
            />
            <div className={styles.sectionImgContainer}>
              <Design className={styles.img} />
            </div>
          </div>
        </div>
        <div className={styles.section2}>
          <Card
            img={<Design />}
            title={"Research & strategy"}
            text="We understand real-world users’ needs and gather insights that are the basis for formulating the strategy for your products."
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDesign;
