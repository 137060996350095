import React from "react";
import styles from "./styles/WebDevelopment.module.scss";

const WebDevelopment = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>WebDevelopment</div>
    </div>
  );
};

export default WebDevelopment;
