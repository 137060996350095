import styles from "./SectionHeading.module.scss";
import classNames from "classnames";

export default function SectionHeading({ heading, subHeading }) {
  return (
    <div className={styles.sectionHeadingContainer}>
      <p className={classNames(styles.title, styles.scrollTransition)}>
        {subHeading}
      </p>
      <h2 className={classNames(styles.heading, styles.scrollTransition)}>
        {heading}
      </h2>
    </div>
  );
}
