import classNames from 'classnames';
import styles from './Dots.module.scss';

export default function Dots({ index, sliderIndex, setSliderIndex }) {
  return (
    <div
      key={index}
      className={classNames(styles.slideshowDot, {
        [styles.active]: sliderIndex === index,
      })}
      onClick={() => {
        setSliderIndex(index);
      }}
    />
  );
}
