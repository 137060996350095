import React from "react";
import styles from "./styles/QualityAssurance.module.scss";

const QualityAssurance = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>QualityAssurance</div>
    </div>
  );
};

export default QualityAssurance;
