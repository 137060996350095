import styles from "../Home.module.scss";
import { whatWeOffer } from "../../../constants/Home";
import TilesContainer from "../../../components/TilesContainer/TilesContainer";
import Tile from "../../../components/Tile/Tile";
import SectionHeading from "../../../components/SectionHeading/SectionHeading";
import React, { useState } from "react";

const Section1 = () => {
  const randomIndex = Math.floor(Math.random() * whatWeOffer.length);
  const [selectedId, setSelectedId] = useState(randomIndex);

  const handleTileClick = (id) => {
    setSelectedId(id);
  };
  return (
    <div className={styles.section1}>
      <div className={styles.wrapper}>
        <SectionHeading
          subHeading="What we offer"
          heading="We transform your ideas into reality by building custom end-to-end products best suited to your business"
        />
        <TilesContainer selectedTile={whatWeOffer[selectedId]}>
          {whatWeOffer.map((item, index) => (
            <Tile
              heading={item.heading}
              Icon={item.icon}
              id={index}
              key={index}
              text={item.text}
              onClick={handleTileClick}
              selectedId={selectedId}
            />
          ))}
        </TilesContainer>
      </div>
    </div>
  );
};

export default Section1;
