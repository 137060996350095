import styles from "../DetailCard/DetailCard.module.scss";

export default function DetailCard({ heading, text }) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.title}>{heading}</p>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
}
