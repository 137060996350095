import styles from "../Button/Button.module.scss";

export const mapSize = {
    large: styles.large,
    medium: styles.medium,
    small: styles.small,
};
export const mapIconPosition = {
    left: styles.iconLeft,
    right: styles.iconRight,
};

export const mapStyle = {
    primary: styles.primary,
    secondary: styles.secondary,
    tertiary: styles.tertiary,
    quaternary: styles.quaternary,
    mobileNav: styles.mobileNav
};